import React from 'react'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Section from './Section'
import Heading from "./Heading";
function FAQ() {
  return (
    <Section id="faq">
   <div  className=" mx-4">  
      <Heading
          title="Frequently questions"
          text="Everything you need to know about ArkSentry"
        />
   <div>
     <Disclosure as="div" className="p-6  mx-8" >
       <DisclosureButton className="group flex w-full items-center justify-between">
         <span className="flex  items-center gap-2 h6 font-medium  group-data-[hover]:text-white/80">
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#6d479c" className="size-7">
  <path stroke-linecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
</svg>
   Who is ArkSentry Powered By?
         </span>
         <ChevronDownIcon className="size-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180" />
       </DisclosureButton>
       <DisclosurePanel className="mt-2 text-sm text-white animate-slideDown pl-2">
ArkSentry is brought to life by a team with a strong technical team, boasting an average experience of over six years in offensive security.
       </DisclosurePanel>
     </Disclosure>

     <Disclosure as="div" className="p-6 mx-8 ">
       <DisclosureButton className="group flex w-full items-center justify-between">
         <span className="flex  items-center gap-2 h6 font-medium text-white group-data-[hover]:text-white/80">
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#6d479c" className="size-7">
  <path stroke-linecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
</svg>

 Asset Monitoring: What Can I Control?
         </span>
         <ChevronDownIcon className="size-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180" />
       </DisclosureButton>
       <DisclosurePanel className="mt-2 text-sm/5 text-white animate-slideDown flex-wrap pr-8 pl-2">At ArkSentry, due to the sensitive information that our modules could reveal, we implement strict controls over asset verification to ensure they're legitimately under your purview.</DisclosurePanel>
     </Disclosure>

     <Disclosure as="div" className="p-6 mx-8" >
       <DisclosureButton className="group flex w-full items-center justify-between">
         <span className="flex items-center gap-2 h6 font-medium text-white group-data-[hover]:text-white/80">
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#6d479c" className="size-7">
  <path stroke-linecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
</svg>

 Will Utilizing ArkSentry Affect My Business Operations?
         </span>
         <ChevronDownIcon className="size-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180" />
       </DisclosureButton>
       <DisclosurePanel className="mt-2 text-sm/5 text-white animate-slideDown pr-4 pl-2">
       ArkSentry operates in a non-intrusive manner, ensuring no disruption to your business activities. Active reconnaissance measures are only undertaken upon explicit request and are executed with the utmost caution by our team.
       </DisclosurePanel>
     </Disclosure>

     <Disclosure as="div" className="p-6 mx-8" >
       <DisclosureButton className="group flex w-full items-center justify-between">
         <span className="flex items-center gap-2 h6 font-medium text-white group-data-[hover]:text-white/80">
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#6d479c" className="size-7">
  <path stroke-linecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
</svg>How Do I Get Alerts on Compromised Credentials?
         </span>
         <ChevronDownIcon className="size-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180" />
       </DisclosureButton>
       <DisclosurePanel className="mt-2 text-sm/5 text-white animate-slideDown pr-5 pl-2">
       ArkSentry delivers real-time notifications for compromised credentials. You can receive alerts and detailed information via the ArkSentry portal or through our supported integrations, including webhooks, Slack, Jira, and PagerDuty.
       </DisclosurePanel>
     </Disclosure>
     <Disclosure as="div" className="p-6 mx-8" >
       <DisclosureButton className="group flex w-full items-center justify-between">
         <span className="flex items-center gap-2 h6 font-medium text-white group-data-[hover]:text-white/80">
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#6d479c" className="size-7">
  <path stroke-linecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
</svg> How Often is the ArkSentry Dataset Updated?
         </span>
         <ChevronDownIcon className="size-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180" />
       </DisclosureButton>
       <DisclosurePanel className="mt-2 text-sm/5 text-white animate-slideDown pr-4 pl-2">
       Our system constantly parses various data sources through an always-on automated pipeline. Additionally, scheduled jobs specifically search for any new leaks or mentions related to our clients every half hour.
       </DisclosurePanel>
     </Disclosure>

     <Disclosure as="div" className="p-6 mx-8" >
       <DisclosureButton className="group flex w-full items-center justify-between">
         <span className="flex items-center gap-2 h6 font-medium text-white group-data-[hover]:text-white/80">
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#6d479c" className="size-7">
  <path stroke-linecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
</svg> Can I Also Monitor My Users' Login Credentials?
         </span>
         <ChevronDownIcon className="size-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180" />
       </DisclosureButton>
       <DisclosurePanel className="mt-2 text-sm/5 text-white animate-slideDown pr-8 pl-2">
       Yes, ArkSentry oversees all client-owned login URLs to safeguard sensitive information. We exclusively share credentials related to your company with you, enabling you to take necessary action, such as revocation, for your customers' protection.
       </DisclosurePanel>
     </Disclosure>

     <Disclosure as="div" className="p-6 mx-8" >
       <DisclosureButton className="group flex w-full items-center justify-between">
         <span className="flex items-center gap-2 h6 font-medium text-white group-data-[hover]:text-white/80">
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#6d479c" className="size-7">
  <path stroke-linecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
</svg> Can ArkSentry Help with Incident Response?
         </span>
         <ChevronDownIcon className="size-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180" />
       </DisclosureButton>
       <DisclosurePanel className=" mt-2 text-sm/5 text-white animate-slideDown pr-8 pl-2">
       ArkSentry's incident response protocol swiftly mobilizes a specialized team to contain and mitigate security breaches, guiding clients through immediate and effective containment measures and recovery actions. Our approach emphasizes rapid resolution, transparent communication, and comprehensive support to ensure client resilience and security posture enhancement.
       </DisclosurePanel>
     </Disclosure>
   </div>
 </div>
    </Section>
  )
}

export default FAQ