import React from 'react'
import Navbar from '../../../components/SecondNav';
function BlogDetails() {

  return (
   <div className=" py-24 sm:py-32">
     <Navbar />
   <div className="mx-auto max-w-7xl px-6 lg:px-8">
     <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
       <div className="lg:pr-4">
         <div className=" animate-fade relative overflow-hidden ">
           <img
             className="inset-0 h-[500px] w-full object-cover rounded-3xl"
             src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80"
             alt=""
           />
         </div>
       </div>
       <div>
         <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
           <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
           Boost your conversion rate
           </h1>
           <div className="max-w-xl text-white">
             <p className="mt-6">
               Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet
               vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque
               erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris
               semper sed amet vitae sed turpis id.
             </p>
             <p className="mt-8">
               Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie
               auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices
               hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.
             </p>
             <p className="mt-8">
               Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie
               auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices
               hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.
             </p>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
  )
}

export default BlogDetails