import React from 'react'
import Navbar from '../../components/SecondNav';
import Section from '../../components/Section'
import { Gradient } from "../../components/design/Roadmap";
import { NavLink } from "react-router-dom";
import Footer from '../../components/Footer';
import logo from "../../assets/images/logo.png";
import line from "../../assets/line.svg";

function Privacy() {


   const privacy = [
      {
         id: 1,
         title: 'Collection of Information',
         description: ``,
         multiple: true,
         data: [
            {
               title: '',
               description: 'Personal data you provide upon service registration, such as your name, email, and contact information, is collected by us.'
            },
            {
               title: '',
               description: 'Automatically gathered information, like your IP, browser specifics, device details, and browsing behavior, is also collected when you visit our site.'
            },
         ]
      },

      {
         id: 3,
         title: 'Utilization of Your Data',
         description: `Your information is used in several ways:`,
         multiple: true,
         data: [
            {
               title: 'a. Service Delivery',
               description: 'For the provision, enhancement, and maintenance of our Services.'
            },
            {
               title: 'b. Communication',
               description: 'For corresponding with you, addressing your questions, and for the delivery of updates and promotional content.'
            },
            {
               title: 'c. Analytics',
               description: 'To assess service usage trends and improve our offerings.'
            },
            {
               title: 'd. Security',
               description: 'To safeguard our website and its users from unauthorized access and threats.'
            }
         ]
      },

      {
         id: 4,
         title: 'Disclosure of Your Information',
         description: `Your personal data is neither sold, traded, nor transferred to external parties by us.         `,
         multiple: true,
         data: [
            {
               title: 'a. Legal Obligations',
               description: 'Your information might be disclosed if required by law, to protect our rights, or following a legal order or request.'
            },
         ]
      },


      {
         id: 5,
         title: 'Cookies and Other Tracking Methods',
         description: `
         To collect data regarding your web preferences and activities, we employ cookies and similar tracking technologies. These settings can be managed via your browser.`,
         multiple: false,
      },
      {
         id: 6,
         title: 'Data Security',
         description: ` 
We make reasonable efforts to secure your personal data from unauthorized access and exposure. Nonetheless, the security of data transmitted over the internet cannot be fully guaranteed.`,
         multiple: false,
      },
      {
         id: 7,
         title: 'External Website Links',
         description: `     
  Links to third-party sites may be included on our website. We are not accountable for the privacy practices or content of external sites and advise you to review their privacy policies.`,
         multiple: false,
      },
      {
         id: 8,
         title: 'Your Rights',
         description: `
         
You hold the right to access, modify, or delete your personal data. You also have the option to opt-out of receiving our promotional communications.`,
         multiple: false,
      },

      {
         id: 9,
         title: 'Policy Updates',
         description: `
         This Privacy Policy may be revised periodically. The "Update Date" at the beginning of this document indicates the latest update.`,
         multiple: false,
      }
   ]

   return (
      <Section className="pt-[5rem] -mt-[5.25rem]"
         crosses
         crossesOffset="lg:translate-y-[5.25rem]"
         customPaddings>
         <Navbar />
         <div className=" w-full py-24 sm:py-32 ">
            <div className="mx-auto max-w-7xl px-6 lg:px-8 relative">
               <div className=" lg:grid grid-cols-12 items-center mx-4">
       
        <div className=' animate-slideDown lg:col-span-8 col-span-12 '>
         <p className=' font-bold text-white font-grotesk text-4xl py-4'>Privacy Policy</p>
         <p className=' leading-relaxed font-code text-sm font-normal'>Welcome to ArkSentry ("Company," "we," "us," "our"). We prioritize your privacy. This document outlines how we handle your personal data, including its collection, usage, sharing, and protection when you engage with our website and offerings ("Services").
By utilizing our Services, you consent to this Privacy Policy. If you disagree with any part of this document, please refrain from using our Services.</p>
        </div>

        <div className=' col-span-4 hidden lg:block'>
        <img src={logo} className=' w-3/4' alt="ArkSentry" />
        </div>
               </div>
               <Gradient />
               <img src={line} className=' opacity-30 absolute top-[320px] left-8 object-cover lg:w-[95%] w-full  ' alt="ArkSentry" />

               <div className="  lg:!mt-[180px] mt-[100px] animate-slideDown mx-auto   max-w-2xl auto-rows-fr  gap-8 sm:mt-20 lg:mx-0 lg:max-w-none">
                  {privacy.map((term) => (
                     <div className='my-8 mx-4'>
                        <div className=' flex items-center gap-2'>
                           <div className=' w-2 h-2 rounded-full bg-white' />
                           <h1 className=' text-white font-grotesk text-2xl font-bold'>{term.title}</h1>
                        </div>
                        {term.multiple === true ?(
                            <p className='px-4 pb-2 font-grotesk text-base font-medium text-white '>{term.description}</p>
                        ):null

                        }
                        {term.multiple === true ? term.data.map((el, i) => (
                           <div className={i > 0 ? 'pt-4' : ''}>

                              <p className='px-4 '>{el.title}</p>
                              
                              <p className='px-4  font-code text-base font-medium text-gray-300'>{el.description}</p>
                           </div>

                        )) : (
                           <p className='px-8 font-code text-base font-medium text-gray-300 '>{term.description}</p>
                        )}

                        

                     </div>
                  ))}

                  <p className='text-center lg:flex justify-center font-grotesk text-lg font-bold mt-20'>For questions or concerns regarding these Terms, please reach out to us at <span className=' underline text-blue-500 px-1 cursor-pointer'> team@arksentry.com</span></p>
               </div>
            </div>
         </div>
         <Footer />
      </Section>
   )
}

export default Privacy