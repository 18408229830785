import React from 'react';
import GridGlobee from "./design/GridGlobe.tsx"

const GridGlobe = () => {
  return (
    <div>
    <GridGlobee/>
    </div>
  );
};

export default React.memo(GridGlobe);
