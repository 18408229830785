import { companyLogos } from "../constants";

const CompanyLogos = ({ className }) => {
  return (
    <div className={className}>
   <p className=" text-transparent">logos</p>
 
    </div>
  );
};

export default CompanyLogos;
