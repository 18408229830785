
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router,Routes, Route } from "react-router-dom";
import App from "./App";
import "./index.css";
import Contact from "./pages/Contact-us/index";
import Blog from "./pages/Blog/index";
import BlogDetails from "./pages/Blog/Details/index";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />}/>
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />

        <Route path="/blog/:id" element={<BlogDetails />} />

      </Routes>

    </Router>
  </React.StrictMode>
);
