import Section from "./Section";
import Heading from "./Heading";
import { service1,  check } from "../assets";
import { arksentryServices } from "../constants";
import {
  Gradient,
} from "./design/Services";

const Services = () => {
  return (
    <Section id="how-to-use">
      <div className="container">
        <Heading
          title="Why You Need ArkSentry"
          text="Stay Ahead of Cyber Threats with Our Advanced Security Services"
        />

        <div className="relative">
          <div className="relative z-1 flex items-center h-[39rem] mb-5 p-8 border border-n-1/10 rounded-3xl overflow-hidden lg:p-20 xl:h-[46rem]">
            <div className="absolute top-0 left-0 w-full h-full pointer-events-none md:w-3/5 xl:w-auto">
              <img
                className=" h-full object-contain w-[90%] px-4  md:object-right hidden lg:block"
               
                alt="Smartest AI"
                src={service1}
              />
            </div>

            <div className="relative z-1 max-w-[17rem] ml-auto">
              <h4 className="h5 mb-4 lg:whitespace-nowrap">Stay Secure, Stay Safe</h4>
              <ul className="body-2">
                {arksentryServices.map((item, index) => (
                  <li
                    key={index}
                    className="flex items-start py-4 border-t border-n-6"
                  >
                    <img width={24} height={24} src={check} />
                    <p className="ml-4">{item}</p>
                  </li>
                ))}
              </ul>
            </div>

         
          </div>

          <Gradient />
        </div>
      </div>
    </Section>
  );
};

export default Services;
