import React from 'react'
/* import { BackgroundCircles } from "../../components/design/Hero"; */
import Navbar from '../../components/SecondNav';
import Section from '../../components/Section'
import { Gradient } from "../../components/design/Roadmap";
import GridGlobe from "../../components/memorizeGlobal.jsx"
import Button from "../../components/Button";
import Footer from '../../components/Footer';
import { useForm } from 'react-hook-form';

function Contact() {
  const { register, handleSubmit, formState: { errors }, trigger } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  }
  return (
    <Section className="pt-[5rem] -mt-[5.25rem] animate-slideDown lg:animate-none "
      crosses
      crossesOffset="lg:translate-y-[5.25rem]"
      customPaddings>
      <Navbar />
      <div className="w-full block lg:flex justify-between items-start ">

        <div className=" w-full lg:w-1/2 py-24 sm:py-32 container">

          <h1 className=" mt-20 lg:mt-0 lg:whitespace-nowrap lg:text-3xl  text-center text-white bg-clip-text text-transparent">
            Have questions? Eager to get started?
          </h1>
          <div className="w-full">
            <div className="text-center">

            </div>
            <div className="mt-5">
              <form className=' pl-4 w-full' onSubmit={handleSubmit(onSubmit)}>
                {/* name and company */}
                <div className='flex gap-4'>
                  <div className=' w-1/2'>
                    <p for="name" className="my-2 font-grotesk">Name</p>
                    <input
                      type="text"
                      placeholder='Enter your name'
                      {...register("name", { required: true })}
                      onBlur={() => trigger("name")}
                      className={`font-grotesk ${errors.name ? 'border-red-500' : 'border-gray-700'} outline-none   px-2 border rounded-lg w-full h-10`} />
                    {errors.name && <p className=' text-red-500 font-grotesk text-sm' >Invalid name</p>}
                  </div>
                  <div className=' w-1/2'>
                    <p for="Company" className="my-2 font-grotesk">Company</p>
                    <input type="text"
                      {...register("Company", { required: true })}
                      onBlur={() => trigger("Company")}
                      placeholder='Enter your company name'
                      className={`font-grotesk ${errors.Company ? 'border-red-500' : 'border-gray-700'} outline-none   px-2 border rounded-lg w-full h-10`} />
                    {errors.Company && <p className=' text-red-500 font-grotesk text-sm' >Invalid company name</p>}
                  </div>
                </div>
                {/* email and phone */}
                <div className='flex gap-4 my-4'>
                  <div className=' w-1/2'>
                    <p for="email" className="my-2 font-grotesk">Email</p>
                    <input type="text"     {...register("email",
                      {
                        required: true,
                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      })}
                      onBlur={() => trigger("email")}
                      placeholder='Enter your email'
                      className={`font-grotesk ${errors.email ? 'border-red-500' : 'border-gray-700'} outline-none  px-2 border rounded-lg w-full h-10`} />

                    {errors.email && <p className=' text-red-500 font-grotesk text-sm'>Invalid email address</p>}
                  </div>
                  <div className=' w-1/2'>
                    <p for="Phone" className="my-2 font-grotesk">Phone</p>
                    <input type="text" placeholder='Enter your phone' className="font-grotesk border-gray-700 outline-non  px-2 border rounded-lg w-full h-10" />
                  </div>
                </div>
                {/* message */}
                {/* pt-2 font-grotesk border-gray-700 outline-none   px-2 border rounded-lg w-full h-16 */}
                <div>
                  <p for="Message" className="mb-2 mt-5 font-grotesk">Message</p>
                  <textarea type="text" placeholder='Enter your message'
                    {...register("Message", { required: true })}
                    onBlur={() => trigger("Message")}
                    className={`pt-2  font-grotesk ${errors.Message ? 'border-red-500' : 'border-gray-700'} outline-none  px-2 border rounded-lg w-full h-16`} />

                  {errors.Message && <p className=' text-red-500 font-grotesk text-sm' >Invalid message</p>}
                </div>
                <div className='flex justify-end w-full mt-8'>

                  <Button type="submit">
                    Send
                  </Button>
                </div>
              </form>
            </div>
          </div>

        </div>
        <div className="lg:w-[50%] hidden lg:block  mt-15">
          <GridGlobe />
        </div>
      </div>
      <Gradient />
      {/*  <BackgroundCircles /> */}
      <Footer />
    </Section>

  )
}

export default Contact