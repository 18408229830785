import React, { useEffect } from 'react';
import ButtonGradient from "./assets/svg/ButtonGradient";
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Footer from './components/Footer';
import HowItWorks from './components/HowItWorks';
import FAQ from './components/FAQ';
import Services from './components/Services'
import Benefits from "./components/Benefits";
import Collaboration from "./components/Collaboration";
import Intercom from '@intercom/messenger-js-sdk';
import * as amplitude from '@amplitude/analytics-browser';

const App = () => {
  
  useEffect(() => {
    amplitude.init('80af4d103cdfddb1ef9b3fba80698821');
    Intercom({
      app_id: 'zo9mdu5s',
    });
  }, []);

  return ( 
 <>
    <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
    <Navbar/>
      <Hero />
      <Benefits />
        <Collaboration />
         <Services/>
        <HowItWorks/>
          <FAQ/>
         <Footer/>
    </div>
    <ButtonGradient />
  </>
  );
}

export default App;
