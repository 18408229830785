import React from 'react'
import logo from "../assets/images/logo.png";
import Section from "./Section";
import { NavLink } from "react-router-dom";
function Footer() {
  return (
	<Section crosses className="!px-0 !py-10">
	<div className=" px-4 mx-10 flex flex-col justify-between py-10 space-y-8 lg:flex-row lg:space-y-0">
		<div className="lg:w-1/3">
			<a rel="noopener noreferrer" href="#" className="flex justify-center space-x-3 lg:justify-start">
					<div className="flex items-center justify-center w-12 h-12 rounded-full ">
						 <img className="w-20" src={logo} alt="ARKSENTRY" />
					</div>
				<span className="self-center text-2xl font-semibold !text-white">ARKSENTRY</span>
			</a>
		</div>
		<div className="grid grid-cols-2 text-sm gap-x-3 gap-y-8 lg:w-2/3 sm:grid-cols-4">
			<div className="space-y-3">
				<h3 className="tracki uppercase text-white">Product</h3>
				<ul className="space-y-1 text-white" >
					<li>
						<a rel="noopener noreferrer " href="/#features">Features</a>
					</li>
					<li>
						<a rel="noopener noreferrer" href="/#integrations">Integrations</a>
					</li>
					<li>
					<NavLink
						to="/blog">
						Blog
						</NavLink>
					</li>
					<li>
						<a rel="noopener noreferrer" href="/#faq">FAQ</a>
					</li>
				</ul>
			</div>
			<div className="space-y-3">
				<h3 className="tracki uppercase text-white">Company</h3>
				<ul className="space-y-1 text-white">
					<li>
						<a rel="noopener noreferrer" href="/privacy">Privacy</a>
					</li>
					<li>
						<a rel="noopener noreferrer" href="/terms">Terms of Service</a>
					</li>
				</ul>
			</div>
			<div className="space-y-3">
				<h3 className="uppercase text-white">partners</h3>
				<ul className="space-y-1 text-white">
					<li>
						<a rel="noopener noreferrer" href="#">Documentation</a>
					</li>
				</ul>
			</div>
			<div className="space-y-3">
				<div className="uppercase text-white">Social media</div>
				<div className="flex justify-start space-x-3">
					<a rel="noopener noreferrer" href="https://x.com/ark_sentry" title="x" className="flex items-center p-1">
					<svg  viewBox="0 0 24 24" className="w-5 h-5 fill-white">
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
					</a>
					<a rel="noopener noreferrer" href="https://www.linkedin.com/company/arksentry/" title="Linkedin" className="flex items-center p-1">
						
						<svg  viewBox="0 0 24 24" className="w-5 h-5 fill-white">
          <path d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 01-1.548-1.549 1.548 1.548 0 111.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z" />
        </svg>
					</a>
				</div>
			</div>
		</div>
	</div>
	<div className="py-6 text-sm text-center text-white">© 2024 ArkSentry, LLC. All rights reserved.</div>
</Section>
  )
}

export default Footer