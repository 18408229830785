
import Button from "./Button";
import Heading from "./Heading";
import Section from "./Section";
import { roadmap } from "../constants";
import {  grid, smallSphere, stars  } from "../assets";
import { Gradient } from "./design/Roadmap";

const HowItWorks = () => (
  <Section className="overflow-hidden " id="roadmap">
    <div className="container md:pb-10 !z-[100]">
      <Heading tag="Ready to get started" title="How ArkSentry work" />

      <div className="relative grid gap-6 md:grid-cols-2 md:gap-4 md:pb-[7rem]">
        {roadmap.map((item) => {
          return (
            <div
              className={` transition duration-300 bg-n-6 ease-in-out transform  hover:bg-conic-gradient hover:scale-95  md:flex even:md:translate-y-[7rem] p-0.25 rounded-[2.5rem]`}
              key={item.id}
            >
              <div className="relative p-8 bg-n-8 rounded-[2.4375rem] overflow-hidden xl:p-15">
                <div className="absolute top-0 left-0 max-w-full">
                  <img
                    className="w-full"
                    src={grid}
                    width={550}
                    height={550}
                    alt="Grid"
                  />
                </div>
                <div className="relative z-1">
                  <div className="mb-10 -my-10 -mx-15 flex justify-center ">
                    <img
                      className={item.id === '0'?' w-[75%]':'w-full'}
                      src={item.imageUrl}
                      width={628}
                      height={426}
                      alt={item.title}
                    />
                  </div>
                  <h4 className="h4 mb-4">{item.title}</h4>
                  <p className="body-2 text-n-4">{item.text}</p>
                </div>
              </div>
            </div>
          );
        })}
        <Gradient />

      </div>

      <div className="flex justify-center mt-12 md:mt-15 xl:mt-20">
        <Button href="/contact">Request a demo</Button>
      </div>
  

      <div className="hidden relative justify-center my-[6.5rem] lg:flex ">
          <img
            src={smallSphere}
            className="relative z-1"
            width={255}
            height={255}
            alt="Sphere"
          />
              
          <div className="absolute top-1/2 left-1/2 w-[60rem] -translate-x-1/2 -translate-y-1/2 pointer-events-none">
            <img
              src={stars}
              className="w-full animate-spin "
              width={950}
              height={400}
              alt="Stars"
            />
          </div>
        </div>
    </div>
  </Section>
);

export default HowItWorks;
