import React from 'react'
import Navbar from '../../components/SecondNav';
import Section from '../../components/Section'
import Heading from "../../components/Heading";
import { Gradient } from "../../components/design/Roadmap";
import Footer from '../../components/Footer';
function Terms() {

   const terms = [
      {
         id: 1,
         title: 'Acceptance of Terms',
         description: `
         By accessing the ArkSentry website ("Website") or using any services provided through our Website, including our Software as a Service (SaaS) product, you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree with all of these Terms, please do not use or access our services.`,
         multiple: false,
      },
      {
         id: 2,
         title: 'Changes to Terms',
         description: `
        ArkSentry reserves the right, at our sole discretion, to modify or revise these Terms at any time without prior notice. Updated Terms will be posted on this page, with the "Last Updated" date at the top of this document amended accordingly. Your continued use of our Website and services following any changes to these Terms constitutes acceptance of those changes.`,
         multiple: false,
      },

      {
         id: 3,
         title: 'Use of the Website and Services',
         description: ``,
         multiple: true,
         data: [
            {
               title: 'a. Account Registration',
               description: 'You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account.'
            },
            {
               title: 'b. User Conduct',
               description: 'You agree not to engage in activities that violate any applicable laws, regulations, or third-party rights, transmit harmful code, or engage in unauthorized access, among other prohibited activities. ArkSentry reserves the right to monitor and take action against any account or content that violates these Terms.'
            }
         ]
      },

      {
         id: 4,
         title: 'Intellectual Property',
         description: `
         All content on the Website, including but not limited to text, graphics, logos, images, and software, is the exclusive property of ArkSentry or its licensors and is protected by international copyright and trademark laws. Use of ArkSentry trademarks or copyrighted material without our express permission is strictly prohibited.`,
         multiple: false,
      },


      {
         id: 5,
         title: 'Privacy',
         description: `
         Your use of the Website and services is also subject to our Privacy Policy, which is incorporated by reference into these Terms.`,
         multiple: false,
      },
      {
         id: 6,
         title: 'Disclaimer of Warranties and Limitation of Liability',
         description: `
         The Website and services are provided on an "as is" and "as available" basis without any warranties, express or implied, of merchantability, fitness for a particular purpose, or non-infringement. ArkSentry shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to these Terms or your use of the Website or services.`,
         multiple: false,
      },
      {
         id: 7,
         title: 'Indemnification',
         description: `     
You agree to indemnify and hold harmless ArkSentry, its officers, directors, employees, and agents from any claims, damages, losses, liabilities, and expenses (including attorneys’ fees) arising out of your use of our Website or services, or violation of these Terms.`,
         multiple: false,
      },
      {
         id: 8,
         title: 'Governing Law',
         description: `
         These Terms shall be governed and construed in accordance with the laws of United States of America, without regard to its conflict of law provisions.`,
         multiple: false,
      },


   ]

   return (
      <Section className="pt-[5rem] -mt-[5.25rem]"
         crosses
         crossesOffset="lg:translate-y-[5.25rem]"
         customPaddings>
         <Navbar />
         <div className=" w-full py-24 sm:py-32 ">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
               <div className="mx-auto max-w-2xl text-center animate-slideDown">
                  <Heading
                     title="Terms Of Use"
                     text="Update Date: April 1, 2024"
                  />
               </div>
               <Gradient />


               <div className=" animate-slideDown mx-auto  max-w-2xl auto-rows-fr  gap-8 sm:mt-20 lg:mx-0 lg:max-w-none">
                  {terms.map((term) => (
                     <div className='my-8 mx-8 text-wrap'>
                        <div className=' flex items-center gap-2'>
                           <div className=' w-2 h-2 rounded-full bg-white' />
                           <h1 className=' text-white font-grotesk text-2xl font-bold'>{term.title}</h1>
                        </div>
                        {term.multiple === true ? term.data.map((el, i) => (
                           <div className={i > 0 ? 'pt-4' : ''}>

                              <p className='px-4 '>{el.title}</p>
                              <p className='px-4  font-code text-base font-medium text-gray-300'>{el.description}</p>
                           </div>

                        )) : null}
                        <p className='px-8 font-code text-base font-medium text-gray-300 '>{term.description}</p>

                     </div>
                  ))}

                  <p className='text-center lg:flex justify-center font-grotesk text-lg font-bold mt-20'>For questions or concerns regarding these Terms, please reach out to us at <span className=' underline text-blue-500 px-1 cursor-pointer'> team@arksentry.com</span></p>
               </div>
            </div>
         </div>
         <Footer />
      </Section>
   )
}

export default Terms